export const sensitiveWords = [
"arsehole",
"assbagger",
"assblaster",
"assclown",
"asscowboy",
"assfuck",
"assfucker",
"asshat",
"asshole",
"assholes",
"asshore",
"assjockey",
"asskiss",
"asskisser",
"assklown",
"asslick",
"asslicker",
"asslover",
"assman",
"assmonkey",
"assmunch",
"assmuncher",
"asspacker",
"asspirate",
"asspuppies",
"assranger",
"asswhore",
"asswipe",
"bitchslap",
"blowjob",
"bootycall",
"breastlover",
"breastman",
"bullcrap",
"bulldike",
"bulldyke",
"bullshit",
"bullshits",
"bullshitted",
"bullshitting",
"bumblefuck",
"bumfuck",
"butchbabes",
"butchdike",
"butchdyke",
"buttbang",
"buttface",
"buttfuck",
"buttfucker",
"buttfuckers",
"buttman",
"buttmunch",
"buttmuncher",
"buttpirate",
"buttplug",
"buttstain",
"carpetmuncher",
"chickenshit",
"chickenshits",
"cockblock",
"cockblocker",
"cockcowboy",
"cockfight",
"cockhead",
"cockknob",
"cocklicker",
"cocklover",
"cocknob",
"cockqueen",
"cockrider",
"cocksman",
"cocksmith",
"cocksmoker",
"cocksucer",
"cocksuck ",
"cocksucked ",
"cocksucker",
"cocksuckers",
"cocksucking",
"crackwhore",
"crotchjockey",
"crotchmonkey",
"cumbubble",
"cumfest",
"cumjockey",
"cumm",
"cummer",
"cumming",
"cumqueen",
"cumshot",
"cunt",
"cunteyed",
"cuntfuck",
"cuntfucker",
"cuntlick ",
"cuntlicker ",
"cuntlicking",
"cunts",
"cuntsucker",
"datnigga",
"dickbrain",
"dickforbrains",
"dickhead",
"dickless",
"dicklick",
"dicklicker",
"dickman",
"dickwad",
"dickweed",
"diddlyshit",
"dogshit",
"dumbass",
"dumbbitch",
"dumbfuck",
"dyke",
"fatass",
"fuck",
"fucked",
"fucker",
"fuckers",
"fuckface",
"fuckfest",
"fuckfreak",
"fuckfriend",
"fuckhead",
"fuckher",
"fuckin",
"fuckina",
"fucking",
"fuckingbitch",
"fuckinnuts",
"fuckinright",
"fuckit",
"fuckknob",
"fuckme ",
"fuckmehard",
"fuckmonkey",
"fuckoff",
"fuckpig",
"fucks",
"fucktard",
"fuckup",
"fuckups",
"fuckwhore",
"horseshit",
"horseshits",
"jizz",
"jizzim",
"jizzum",
"kike",
"lez",
"mothafuck",
"mothafucka",
"mothafuckaz",
"mothafucked ",
"mothafucker",
"mothafuckin",
"mothafucking ",
"mothafuckings",
"motherfuck",
"motherfucked",
"motherfucker",
"motherfuckin",
"motherfucking",
"motherfuckings",
"niggard",
"niggardly",
"nigger",
"niggers",
"paki",
"shit",
"shitcan",
"shitdick",
"shite",
"shiteater",
"shited",
"shitface",
"shitfaced",
"shitfit",
"shitforbrains",
"shitfuck",
"shitfucker",
"shitfull",
"shithapens",
"shithappens",
"shithead",
"shitheads",
"shithouse",
"shiting",
"shitless",
"shitlist",
"shitola",
"shitoutofluck",
"shits",
"shitstain",
"shitted",
"shitter",
"shittier",
"shittiest",
"shitting",
"shitty",
"shitty ",
"shitwork",
"spic",
"titfuck",
"titfucker",
"whoreson",
"wop",
];
